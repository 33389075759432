import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Registration = () => {
    const { t } = useTranslation();
    const { referralCode } = useParams();  // Extract the referral code from URL params
    const [formData, setFormData] = useState({
        orgType: '1',
        invoiceInterval: '1',
        orgNum: '',
        companyName: '',
        address: '',
        givenName: '',
        surName: '',
        emailContact: '',
        emailInvoice: '',
        conditions: false
    });
    const [price, setPrice] = useState(195);  // Default price for monthly company subscription
    const [discountedPrice, setDiscountedPrice] = useState(null);  // Apply discount if referral code exists
    const [error, setError] = useState(null);

    const PRICE_COMPANY = 195;
    const PRICE_BOOKKEEPING = 995;
    const PRICE_YEAR_MONTHS = 10;

    useEffect(() => {
        const { orgType, invoiceInterval } = formData;
        let basePrice = orgType === '1' ? PRICE_COMPANY : PRICE_BOOKKEEPING;

        // Apply yearly payment calculation
        if (invoiceInterval === '2') {
            const yearlyPrice = basePrice * PRICE_YEAR_MONTHS;
            // If yearly and referral code is provided, apply 9-month equivalent discount
            const yearlyDiscountPrice = referralCode ? Math.floor(basePrice * 9) : yearlyPrice;
            setDiscountedPrice(referralCode ? yearlyDiscountPrice : null);
            setPrice(yearlyPrice);
        } else {
            // For monthly subscriptions, apply 50% off first month if referral code is present
            const discountedMonthPrice = referralCode ? Math.floor(basePrice / 2) : basePrice;
            setDiscountedPrice(referralCode ? discountedMonthPrice : null);
            setPrice(basePrice);
        }
    }, [formData.orgType, formData.invoiceInterval, referralCode]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.conditions) {
            setError(t('You must accept the terms and conditions.'));
            return;
        }

        // Perform the form submission logic here (API call, etc.)
        const submissionData = {
            ...formData,
            referralCode,  // Include the referral code in the submission data
        };
        console.log('Form Submitted:', submissionData);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex justify-center items-center">
            <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
                <h1 className="text-3xl mb-6">{t('RegisterAccount')}</h1>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Hidden input for referral code */}
                    {referralCode && <input type="hidden" name="referralCode" value={referralCode} />}

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="col-span-2 md:col-span-1">
                            <label htmlFor="orgType" className="block text-sm font-medium text-gray-700">
                                {t('AccountType')}
                            </label>
                            <select
                                name="orgType"
                                id="orgType"
                                value={formData.orgType}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            >
                                <option value="1">{t('Company / Individual')}</option>
                                <option value="2">{t('AccountingFirm')}</option>
                            </select>
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <label htmlFor="invoiceInterval" className="block text-sm font-medium text-gray-700">
                                {t('InvoiceInterval')}
                            </label>
                            <select
                                name="invoiceInterval"
                                id="invoiceInterval"
                                value={formData.invoiceInterval}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                            >
                                <option value="1">{t('Monthly')}</option>
                                <option value="2">{t('Yearly')}</option>
                            </select>
                        </div>

                        {/* Organization number and company information */}
                        <div className="col-span-2">
                            <label htmlFor="orgNum" className="block text-sm font-medium text-gray-700">
                                {t('organizationNumber')}
                            </label>
                            <input
                                type="text"
                                name="orgNum"
                                id="orgNum"
                                value={formData.orgNum}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                placeholder="123456-7890"
                                required
                            />
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                                {t('companyName')}
                            </label>
                            <input
                                type="text"
                                name="companyName"
                                id="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                {t('billingAddress')}
                            </label>
                            <textarea
                                name="address"
                                id="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>

                        {/* User name and contact */}
                        <div className="col-span-2 md:col-span-1">
                            <label htmlFor="givenName" className="block text-sm font-medium text-gray-700">
                                {t('firstName')}
                            </label>
                            <input
                                type="text"
                                name="givenName"
                                id="givenName"
                                value={formData.givenName}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>

                        <div className="col-span-2 md:col-span-1">
                            <label htmlFor="surName" className="block text-sm font-medium text-gray-700">
                                {t('lastName')}
                            </label>
                            <input
                                type="text"
                                name="surName"
                                id="surName"
                                value={formData.surName}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>

                        {/* Emails for contact and invoicing */}
                        <div className="col-span-2">
                            <label htmlFor="emailContact" className="block text-sm font-medium text-gray-700">
                                {t('contactEmail')}
                            </label>
                            <input
                                type="email"
                                name="emailContact"
                                id="emailContact"
                                value={formData.emailContact}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                placeholder="email@gmail.com"
                                required
                            />
                        </div>

                        <div className="col-span-2">
                            <label htmlFor="emailInvoice" className="block text-sm font-medium text-gray-700">
                                {t('invoiceEmail')}
                            </label>
                            <input
                                type="email"
                                name="emailInvoice"
                                id="emailInvoice"
                                value={formData.emailInvoice}
                                onChange={handleInputChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                                placeholder="invoice@yourcompany.com"
                                required
                            />
                        </div>

                        {/* Terms and conditions checkbox */}
                        <div className="col-span-2">
                            <label className="inline-flex items-center">
                                <input
                                    type="checkbox"
                                    name="conditions"
                                    checked={formData.conditions}
                                    onChange={handleInputChange}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    required
                                />
                                <span className="ml-2">{t('termsConditions')} <a href="/terms" target="_blank" className="text-blue-500">{t('termsOfUse')}</a> {t('and')} <a href="/privacy" target="_blank" className="text-blue-500">{t('privacyPolicy')}</a>.</span>
                            </label>
                        </div>
                    </div>

                    {/* Display error if terms are not accepted */}
                    {error && <div className="text-red-500 mb-4">{error}</div>}

                    {/* Pricing information */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="md:col-span-2">
                            <div className="text-lg font-semibold text-center space-y-2">
                                {/* Original Price */}
                                <p>
                                    <span className="font-bold">{discountedPrice !== null ? t('OriginalPrice') : t('Price')}:</span> {price} {t('currency')} {formData.invoiceInterval === '1' ? t('perMonth') : t('perYear')} <span>{t('exVat')}</span>
                                </p>

                                {/* Deduction (Discount) */}
                                {discountedPrice !== null && (
                                    <p className="text-red-500">
                                        <span className="font-bold">{t('Discount')}:</span> -{price - discountedPrice} {t('currency')}
                                    </p>
                                )}

                                {/* New Total with clarification */}
                                {discountedPrice !== null && (
                                    <>
                                        <p className="text-green-600">
                                            <span className="font-bold">{t('NewTotal')}:</span> {discountedPrice} {t('currency')} <span>{t('exVat')}</span>
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            {formData.invoiceInterval === '2'
                                                ? t('ThisAmountCoversFirstInvoiceYearly')
                                                : t('ThisAmountCoversFirstInvoiceMonthly')}
                                        </p>
                                    </>
                                )}

                        </div>
                        </div>
                    </div>

                    {/* Register button */}
                    <div className="flex justify-center">
                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                            {t('Register')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Registration;
