import React, { useState, useEffect } from 'react';
import { apiRequest } from '../../utils/Api';
import { useTranslation } from 'react-i18next';
import ToolFooter from '../../components/ToolFooter';
import FormatSelector from '../../components/FormatSelector';
import FileDownloadButton from '../../components/FileDownloadButton';

const DanskeBank = () => {
    const [file, setFile] = useState(null);
    const [convertedFile, setConvertedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isApiDisabled, setIsApiDisabled] = useState(true);
    const [selectedFormat, setSelectedFormat] = useState('csv');
    const { t } = useTranslation();

    useEffect(() => {
        const apiInfo = localStorage.getItem('apiInfo');
        setIsApiDisabled(apiInfo === 'false');

        const savedFormat = localStorage.getItem('selectedFormat');
        if (savedFormat) {
            setSelectedFormat(savedFormat);
        }
    }, []);

    const apiDocumentation = {
        description: 'Convert a file from Danske Bank to the format required by your bookkeeping software.',
        calls: {
            '/tools/danskebank': 'POST',
        },
        post: {
            file: 'base64-encoded file -- The CSV file to convert',
            format: 'string -- Desired output format',
        },
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!file) {
            setError(t('PleaseSelectFile'));
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/tools/danskebank', 'POST', {
                    file: base64File,
                    format: selectedFormat,
                });

                if (response.error) {
                    setError(response.error);
                } else {
                    setConvertedFile(response.convertedFile);
                }
            } catch (err) {
                setError(t('FailedToProcessFile'));
            }

            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-4">{t('DanskeBankTool')}</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <ol className="list-decimal">
                    <li className="mb-3">
                        <FormatSelector selectedFormat={selectedFormat} setSelectedFormat={setSelectedFormat} />
                    </li>
                    <li className="mb-3">
                        <label htmlFor="fileToUpload" className="block text-sm font-medium text-gray-700">{t('UploadCSVFile')}</label>
                        <input
                            type="file"
                            id="fileToUpload"
                            onChange={handleFileChange}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                        />
                    </li>
                </ol>

                <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-indigo-600 text-white rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? t('Processing') : t('ConvertFile')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-500 text-sm">{error}</div>}

            {convertedFile && (
                <div className="mt-4">
                    <FileDownloadButton convertedFile={convertedFile} selectedFormat={selectedFormat} fileName="converted_danskebank" />
                </div>
            )}

            <ToolFooter apiDocumentation={apiDocumentation} isApiDisabled={isApiDisabled} />
        </div>
    );
};

export default DanskeBank;
