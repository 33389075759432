import React, { useState } from 'react';
import { apiRequest } from '../utils/Api';

const TaskModal = ({ task, onClose }) => {
    const [title, setTitle] = useState(task.title);
    const [description, setDescription] = useState(task.description);

    const handleSave = async () => {
        await apiRequest(`/tasks/task/${task.id}`, 'PATCH', { title, description });
        onClose();
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <input value={title} onChange={(e) => setTitle(e.target.value)} className="input-title" />
                <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="textarea-description" />
                <button onClick={handleSave} className="btn-save">Save</button>
                <button onClick={onClose} className="btn-close">Close</button>
            </div>
        </div>
    );
};

export default TaskModal;
