import React from 'react';
import { NavLink } from 'react-router-dom';

// Constants for Input and Output formats
const INPUT_FORMATS = ['csv', 'xls', 'xlsx', 'pdf', 'txt'];
const OUTPUT_FORMATS = ['csv', 'sie', 'bgmax', 'pdf', 'xls', 'xlsx'];

const ToolFooter = ({ apiDocumentation, isApiDisabled }) => {
    if (isApiDisabled) {
        return null; // Do not render footer if API info is disabled
    }

    return (
        <footer className="py-4 mt-8">
            <div className="container mx-auto">
                <p className="text-sm">
                    Please note all API documentation is for advanced users only. The documentation is only available in
                    English. To turn off the API documentation message please visit the{' '}
                    <NavLink to="/control/user" className="text-blue-500 underline">
                        Control panel
                    </NavLink>.<br/>
                    This tool is also available via our API. To gain access, visit{' '}
                    <NavLink to="/info/api-keys" className="text-blue-500 underline">
                        API Keys Info
                    </NavLink>{' '}
                    to retrieve your own API key and read up on Authentication.
                </p>

                {/* Display API documentation if available */}
                {apiDocumentation && (
                    <div>
                        <h2 className="text-lg font-bold mt-4">API Documentation</h2>

                        {/* Display API description */}
                        <p>{apiDocumentation.description}</p>

                        {/* Display API calls */}
                        <h3 className="text-md font-semibold mt-2">API Calls:</h3>
                        <ul>
                            {Object.keys(apiDocumentation.calls).map((call) => (
                                <li key={call}>
                                    <strong>{process.env.REACT_APP_API_URL}{call}</strong> - {apiDocumentation.calls[call]}
                                </li>
                            ))}
                        </ul>

                        {/* Standard Inputs */}
                        <h3 className="text-md font-semibold mt-2">POST Request Fields:</h3>
                        <table className="table w-full">
                            <thead>
                            <tr>
                                <th>Field</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* Hardcoded standard fields */}
                            <tr>
                                <td>file</td>
                                <td>base64-encoded</td>
                                <td>Input file ({INPUT_FORMATS.join(', ')})</td>
                            </tr>
                            <tr>
                                <td>format</td>
                                <td>string</td>
                                <td>Output format ({OUTPUT_FORMATS.join(', ')})</td>
                            </tr>

                            {/* Display other POST fields from API documentation, excluding 'file' and 'format' */}
                            {Object.keys(apiDocumentation.post)
                                .filter((field) => field !== 'file' && field !== 'format') // Exclude standard fields
                                .map((field) => {
                                    const [description, comment] = apiDocumentation.post[field].split(' -- '); // Split field details
                                    return (
                                        <tr key={field}>
                                            <td>{field}</td>
                                            <td>{description}</td>
                                            <td>{comment}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </footer>
    );
};

export default ToolFooter;
