import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiRequest } from '../utils/Api';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);  // New state for loading status
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);  // Set loading to true

        try {
            const response = await apiRequest('/auth/login', 'POST', {
                email,
                password
            });

            if (response.error) {
                setError(response.error);
            } else {
                // Store the API info in localStorage
                localStorage.setItem('apiInfo', response.apiInfo);
                // Set the token in localStorage for future requests
                localStorage.setItem('token', response.token);
                // Set the user info in localStorage
                localStorage.setItem('user', JSON.stringify(response.user));
                localStorage.setItem('referralCode', response.referralCode);
                location.href = '/dashboard';
            }
        } catch (err) {
            setError('Login failed.');
        } finally {
            setIsLoading(false);  // Set loading to false after request completes
        }
    };

    return (
        <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-4">Login</h1>
            <form onSubmit={handleLogin} className="space-y-4">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}
                           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                           disabled={isLoading} // Disable input when loading
                    />
                </div>
                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                    <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}
                           className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                           disabled={isLoading} // Disable input when loading
                    />
                </div>
                <button type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md"
                        disabled={isLoading} // Disable button when loading
                >
                    {isLoading ? 'Logging in...' : 'Login'}  {/* Show loading text when loading */}
                </button>
            </form>

            {error && <div className="mt-4 text-red-500 text-sm">{error}</div>}
        </div>
    );
};

export default Login;
