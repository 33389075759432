import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import LoggedOutHeader from './components/LoggedOutHeader'; // New header for logged out users
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Login from './pages/Login';
import Sales from './pages/Sales';
import Register from "./pages/Register";
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Bokio from './pages/tools/Bokio';
import CollectorCard from "./pages/tools/CollectorCard";
import Referral from "./pages/Referral";
import ToolFooter from './components/ToolFooter';
import KanbanBoard from "./components/KanbanBoard";
import { isAuthenticated } from './Auth'; // Auth helper function to check if the user is logged in
import './styles/FontAwesome.css';
import Handelsbanken from "./pages/tools/Handelsbanken";
import Lansforsakringar from "./pages/tools/Lansforsakringar";
import Nordea from "./pages/tools/Nordea";
import SveaEkonomi from "./pages/tools/SveaEkonomi";
import Swedbank from "./pages/tools/Swedbank";
import Paypal from "./pages/tools/Paypal";
import MarginalenBank from "./pages/tools/MarginalenBank";
import Avanza from "./pages/tools/Avanza";
import DanskeBank from "./pages/tools/DanskeBank";
import Nets from "./pages/tools/Nets";
import NetsEasy from "./pages/tools/NetsEasy";
import Bambora from "./pages/tools/Bambora";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in on mount
        const checkAuthStatus = () => {
            const loggedIn = isAuthenticated(); // Check if token exists or valid
            setIsLoggedIn(loggedIn);
        };

        checkAuthStatus();
    }, []);

    return (
        <Router>
            <div className="flex">
                {/* Sidebar */}
                {isLoggedIn && <Sidebar />} {/* Show sidebar only if logged in */}

                <div className="flex flex-col w-full">
                    {/* Conditional Header */}
                    {isLoggedIn ? <Header /> : <LoggedOutHeader />} {/* Show appropriate header */}

                    {/* Main Content */}
                    <main className="flex-grow pt-16">
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route path="/sales" element={<Sales />} />
                            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />

                            <Route path="/tool/bokio" element={<ProtectedRoute><Bokio /></ProtectedRoute>} />
                            <Route path="/tool/collectorcard" element={<ProtectedRoute><CollectorCard /></ProtectedRoute>} />
                            <Route path="/tool/handelsbanken" element={<ProtectedRoute><Handelsbanken /></ProtectedRoute>} />
                            <Route path="/tool/lansforsakringar" element={<ProtectedRoute><Lansforsakringar /></ProtectedRoute>} />
                            <Route path="/tool/nordea" element={<ProtectedRoute><Nordea /></ProtectedRoute>} />
                            <Route path="/tool/sveaekonomi" element={<ProtectedRoute><SveaEkonomi /></ProtectedRoute>} />
                            <Route path="/tool/swedbank" element={<ProtectedRoute><Swedbank /></ProtectedRoute>} />
                            <Route path="/tool/paypal" element={<ProtectedRoute><Paypal /></ProtectedRoute>} />
                            <Route path="/tool/marginalenbank" element={<ProtectedRoute><MarginalenBank /></ProtectedRoute>} />
                            <Route path="/tool/avanza" element={<ProtectedRoute><Avanza /></ProtectedRoute>} />
                            <Route path="/tool/danskebank" element={<ProtectedRoute><DanskeBank /></ProtectedRoute>} />
                            <Route path="/tool/netssie" element={<ProtectedRoute><Nets /></ProtectedRoute>} />
                            <Route path="/tool/netseasy" element={<ProtectedRoute><NetsEasy /></ProtectedRoute>} />
                            <Route path="/tool/bambora" element={<ProtectedRoute><Bambora /></ProtectedRoute>} />

                            <Route path="/register" element={<Register />} />
                            <Route path="/register/:referralCode" element={<Register />} />

                            <Route path="/referral" element={<Referral />} />
                            <Route path="/referral/:referralCode" element={<Referral />} />

                            <Route path="/planner" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />
                            <Route path="/planner/:id" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />
                            <Route path="/planner/:id/:cardId" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />
                            <Route path="/planner/:id/:cardId/:action" element={<ProtectedRoute><KanbanBoard /></ProtectedRoute>} />

                            <Route path="*" element={<Navigate to="/sales" />} />
                        </Routes>
                    </main>

                    {/* Footer */}
                    <Footer />

                    {/* Tool Footer */}
                    <ToolFooterWrapper />
                </div>
            </div>
        </Router>
    );
}

function ToolFooterWrapper() {
    const location = useLocation();

    // Check if the current route matches /tool/*
    const isToolPage = location.pathname.startsWith('/tool/');

    return isToolPage ? <ToolFooter /> : null;
}

export default App;
