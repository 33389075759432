import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
    const [isModalOpen, setIsModalOpen] = useState(false); // Referral modal state
    const [isBugModalOpen, setIsBugModalOpen] = useState(false); // Bug modal state
    const [bugMessage, setBugMessage] = useState(''); // Bug message state
    const [bugFile, setBugFile] = useState(null); // Bug file state
    const referralCode = localStorage.getItem('referralCode'); // Fetch referral code from localStorage
    const { t, i18n } = useTranslation();

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        localStorage.setItem('darkMode', !darkMode);
        document.documentElement.classList.toggle('dark', !darkMode);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleLogout = (event) => {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/Auth/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => response.json())
            .then(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('apiInfo');
                localStorage.removeItem('referralCode'); // Clean up referralCode on logout
                window.location.href = '/';
            })
            .catch(error => console.error('Error logging out:', error));
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleBugModal = () => {
        setIsBugModalOpen(!isBugModalOpen);
    };

    const handleFileChange = (e) => {
        setBugFile(e.target.files[0]);
    };

    const handleBugSubmit = async (e) => {
        e.preventDefault();

        if (!bugMessage) {
            alert(t('PleaseEnterMessage'));
            return;
        }

        const formData = new FormData();
        formData.append('message', bugMessage);
        if (bugFile) {
            formData.append('file', bugFile);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/planner/reportBug`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });

            if (response.ok) {
                alert(t('BugReportedSuccessfully'));
                setBugMessage('');
                setBugFile(null);
                toggleBugModal(); // Close the bug modal
            } else {
                alert(t('ErrorReportingBug'));
            }
        } catch (error) {
            console.error('Error reporting bug:', error);
            alert(t('ErrorReportingBug'));
        }
    };

    return (
        <header className="w-full fixed top-0 left-0 z-50 bg-gray-800 text-white p-4">
            <div className="flex justify-between items-center">
                <div className="logo">
                    <a href="/dashboard" className="text-xl font-bold">EkonomiAPI</a>
                </div>
                <nav className="flex space-x-4">
                    {/* Referral Code Button */}
                    {referralCode && (
                        <button onClick={toggleModal} className="relative">
                            <i className="fa-thin fa-share-nodes"></i>
                        </button>
                    )}

                    {/* Bug Report Button */}
                    <button onClick={toggleBugModal} className="relative">
                        <i className="fa-solid fa-bug"></i> {/* Bug icon */}
                    </button>

                    {/* Language Switch and Dark Mode */}
                    <button onClick={() => changeLanguage('sv')} className="hover:underline">SV</button>
                    <button onClick={() => changeLanguage('en')} className="hover:underline">EN</button>
                    <button onClick={toggleDarkMode} className="px-2 py-1 rounded">
                        <i className={darkMode ? 'fa-solid fa-sun-bright fa-fw' : 'fa-solid fa-moon fa-fw'}></i>
                    </button>

                    {/* Logout Button */}
                    <button onClick={handleLogout} className="hover:underline">
                        <i className="fas fa-portal-exit"></i>
                    </button>
                </nav>

                {/* Modal for Referral Code */}
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white text-black p-4 rounded-lg shadow-lg w-80">
                            <h3 className="text-lg font-bold mb-4">{t('your_referral_code')}</h3>
                            <p className="mb-4">{t('share_link_earn_rewards')}:</p>
                            <a
                                href={`/referral/${referralCode}`}
                                className="text-blue-600 underline break-words"
                            >
                                https://web.ekonomiapi.se/referral/{referralCode}
                            </a>
                            <p className="mt-4">{t('share_link_description')}.</p>
                            <div className="mt-4 text-right">
                                <button onClick={toggleModal} className="bg-gray-600 text-white px-4 py-2 rounded">
                                    {t('Close')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for Bug Report */}
                {isBugModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white text-black p-4 rounded-lg shadow-lg w-80">
                            <h3 className="text-lg font-bold mb-4">{t('ReportBug')}</h3>
                            <form onSubmit={handleBugSubmit}>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">{t('BugMessage')}</label>
                                    <textarea
                                        value={bugMessage}
                                        onChange={(e) => setBugMessage(e.target.value)}
                                        rows="4"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">{t('AttachFile')}</label>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                                    />
                                </div>
                                <div className="mt-4 text-right">
                                    <button
                                        type="submit"
                                        className="bg-blue-600 text-white px-4 py-2 rounded"
                                    >
                                        {t('SubmitBug')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={toggleBugModal}
                                        className="ml-2 bg-gray-600 text-white px-4 py-2 rounded"
                                    >
                                        {t('Close')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
