// src/utils/Api.js
export const apiRequest = async (endpoint, method = 'POST', body = null) => {
    const token = localStorage.getItem('token');  // Get the token from localStorage

    const headers = {
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
        'Content-Type': 'application/json',
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;  // Add Authorization header if token exists
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method,
        headers,
        body: body ? JSON.stringify(body) : null,
    });

    return response.json();  // Assuming the API responds with JSON
};

export const apiRequestFile = async (endpoint, formData) => {
    const token = localStorage.getItem('token');  // Get the token from localStorage

    const headers = {
        'X-API-TOKEN': process.env.REACT_APP_API_TOKEN,
        // Do not set Content-Type here because `fetch` will automatically set it to multipart/form-data for FormData
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;  // Add Authorization header if token exists
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: 'POST',
        headers,
        body: formData,  // `formData` is already properly formatted for multipart/form-data
    });

    return response.json();  // Assuming the API responds with JSON
};
