import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Referral = () => {
    const { referralCode } = useParams();  // Get the referral code from the URL
    const [referralData, setReferralData] = useState(null);  // State to store API data
    const [loading, setLoading] = useState(true);  // State to manage loading status
    const [error, setError] = useState(null);  // State to handle error status
    const { t } = useTranslation();  // i18n hook for translation

    const defaultReferralCode = 'ekobonus';  // Hardcoded fallback referral code

    useEffect(() => {
        // Fetch referral data from the API
        const fetchReferralData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/referral/${referralCode}`);
                const data = await response.json();

                if (response.ok && data.referrerName) {
                    setReferralData(data);  // Set the data if API call succeeds
                } else {
                    // If the referral code is invalid or API returns an error, use default referral code
                    setReferralData({
                        referrerName: null,  // No referrer for ekobonus
                        bonusInfo: t('defaultBonus'),
                        referralCode: defaultReferralCode  // Set to hardcoded referral code
                    });
                }
            } catch (err) {
                setError(err.message);  // Set error if API call fails
            } finally {
                setLoading(false);  // Set loading status to false when done
            }
        };

        fetchReferralData();
    }, [referralCode, t]);

    // Show loading spinner or message
    if (loading) {
        return <div>{t('loading')}</div>;
    }

    // Show error message if data fetching fails
    if (error) {
        return <div>{t('error')}: {error}</div>;
    }

    // Destructure the referral data
    const { referrerName, bonusInfo } = referralData || {};
    const finalReferralCode = referralData?.referralCode || referralCode;

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
            <div className="container mx-auto p-6 bg-white shadow-md rounded-lg">
                <h1 className="text-4xl font-bold mb-4 text-center">{t('referralTitle')}</h1>

                {/* Show who referred the user, only if there's a valid referrer */}
                {referrerName ? (
                    <p className="mb-4 text-lg text-center">
                        {t('referralBy', { referrerName })}
                    </p>
                ) : (
                    <p className="mb-4 text-lg text-center">
                        {t('referralBonus', { bonusInfo })}
                    </p>
                )}

                {/* Sales Pitch */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <h2 className="text-2xl font-bold mb-2">{t('referralPitchHeader')}</h2>
                        <ul className="list-disc list-inside mb-4 text-lg">
                            <li><strong>{t('referralList1')}:</strong> {t('referralList1Description')}</li>
                            <li><strong>{t('referralList2')}:</strong> {t('referralList2Description')}</li>
                            <li><strong>{t('referralList3')}:</strong> {t('referralList3Description')}</li>
                            <li><strong>{t('referralList4')}:</strong> {t('referralList4Description')}</li>
                        </ul>

                        <h2 className="text-2xl font-bold mb-2">{t('referralSubHeader')}</h2>
                        <p className="text-lg mb-4">{t('referralPara')}</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                        <img src="/images/timeismoney.webp" alt="EkonomiAPI" className="w-full h-auto" />
                    </div>
                </div>

                {/* Register Button with final referral code */}
                <Link
                    to={`/register/${finalReferralCode}`}
                    className="block p-2 bg-blue-500 text-white rounded text-center mt-6 w-44 mx-auto"
                >
                    {t('referralButton')}
                </Link>
            </div>
        </div>
    );
};

export default Referral;
