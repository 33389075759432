import React, { useState, useEffect } from 'react';
import { apiRequest } from '../utils/Api';
import TaskModal from './TaskModal';

const KanbanBoard = () => {
    const [boards, setBoards] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);

    useEffect(() => {
        // Fetch boards and then fetch task lists for each board
        async function fetchBoardsAndTasks() {
            try {
                // Fetch all boards
                const boardsResponse = await apiRequest('/planner/boards', 'POST');

                // Fetch task lists for each board
                const boardsWithTaskLists = await Promise.all(boardsResponse.map(async (board) => {
                    const taskLists = await apiRequest(`/planner/getTaskLists`, 'POST', { boardId: board.id });

                    // Group tasks by their respective taskListTitles
                    const groupedTasks = taskLists.reduce((acc, task) => {
                        if (!acc[task.taskListTitle]) {
                            acc[task.taskListTitle] = [];
                        }
                        acc[task.taskListTitle].push(task);
                        return acc;
                    }, {});

                    return {
                        ...board,
                        taskLists: groupedTasks, // Attach grouped tasks to the board
                    };
                }));

                setBoards(boardsWithTaskLists);
            } catch (error) {
                console.error('Failed to fetch boards and tasks', error);
            }
        }

        fetchBoardsAndTasks();
    }, []);

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };

    return (
        <div className="flex flex-row h-screen">

            {/* Main content area */}
            <div className="flex-1 p-4 overflow-hidden">
                <div className="kanban-board flex-1 h-full overflow-x-auto">
                    {/* Task boards */}
                    <div className="task-boards flex space-x-4">
                        {boards.map((board) => (
                            <div key={board.id}
                                 className="board-column bg-white p-4 rounded-lg shadow-md min-w-[300px]">
                                <h3 className="text-xl font-semibold mb-4 text-gray-700">{board.title}</h3>
                                <div className="task-lists-wrapper overflow-x-auto">
                                    {/* Task lists arranged horizontally */}
                                    <div className="task-lists flex space-x-4">
                                        {Object.entries(board.taskLists).map(([taskListTitle, tasks]) => (
                                            <div key={taskListTitle} className="task-list min-w-[250px]">
                                                <h4 className="text-lg font-semibold mb-2 text-gray-600">{taskListTitle}</h4>
                                                <div className="tasks-list space-y-2">
                                                    {tasks.map((task) => (
                                                        <div
                                                            key={task.id}
                                                            className="task-card p-3 bg-gray-100 rounded-lg shadow-sm cursor-pointer hover:bg-gray-200 transition-all"
                                                            onClick={() => handleTaskClick(task)}
                                                        >
                                                            <h5 className="font-medium text-gray-800">{task.title}</h5>
                                                            <p className="text-sm text-gray-600 truncate">{task.description}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Modal for selected task */}
                    {selectedTask && (
                        <TaskModal task={selectedTask} onClose={() => setSelectedTask(null)}/>
                    )}
                </div>
            </div>
        </div>

    );
};

export default KanbanBoard;
