import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Constants for available formats
const AVAILABLE_FORMATS = ['csv', 'sie', 'bgmax', 'pdf', 'xls', 'xlsx'];

const FormatSelector = ({ selectedFormat, setSelectedFormat }) => {
    const { t } = useTranslation();

    // On component mount, check for the last selected format in localStorage
    useEffect(() => {
        const savedFormat = localStorage.getItem('selectedFormat');
        if (savedFormat && AVAILABLE_FORMATS.includes(savedFormat)) {
            setSelectedFormat(savedFormat);
        }
    }, [setSelectedFormat]);

    // Handle change in the format selection
    const handleFormatChange = (e) => {
        const newFormat = e.target.value;
        setSelectedFormat(newFormat);
        localStorage.setItem('selectedFormat', newFormat); // Save the selected format in localStorage
    };

    return (
        <div>
            <label htmlFor="format" className="block text-sm font-medium text-gray-700">{t('SelectOutputFormat')}</label>
            <select
                id="format"
                value={selectedFormat}
                onChange={handleFormatChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
                {AVAILABLE_FORMATS.map((format) => (
                    <option key={format} value={format}>
                        {format.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default FormatSelector;
