import React, { useState } from 'react';
import { apiRequest } from '../../utils/Api';
import ToolFooter from '../../components/ToolFooter';
import { useTranslation } from 'react-i18next';

const Lansforsakringar = () => {
    const [file, setFile] = useState(null);
    const [convertedFile, setConvertedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedFormat, setSelectedFormat] = useState('csv'); // Default format
    const { t } = useTranslation();

    const apiDocumentation = {
        description: 'Convert a file from Länsförsäkringar to a format that can be imported into your bookkeeping software.',
        calls: {
            '/tools/lansforsakringar': 'POST'
        },
        post: {
            file: 'base64-encoded -- CSV file'
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (!file) {
            setError(t('PleaseSelectFile'));
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64File = reader.result;

            try {
                const response = await apiRequest('/tools/lansforsakringar', 'POST', {
                    file: base64File
                });

                if (response.error) {
                    setError(response.error);
                } else {
                    setConvertedFile(response.convertedFile);
                }
            } catch (err) {
                setError(t('FailedToProcessFile'));
            }

            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = `data:text/csv;base64,${convertedFile}`;
        a.download = 'converted_lansforsakringar.csv';
        a.click();
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold mb-4">{t('LansforsakringarTool')}</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="fileToUpload" className="block text-sm font-medium text-gray-700">
                        {t('UploadCSVFile')}
                    </label>
                    <input
                        type="file"
                        id="fileToUpload"
                        onChange={handleFileChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    type="submit"
                    className={`w-full py-2 px-4 bg-indigo-600 text-white rounded-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? t('Processing') : t('ConvertFile')}
                </button>
            </form>

            {error && <div className="mt-4 text-red-500 text-sm">{error}</div>}

            {convertedFile && (
                <div className="mt-4">
                    <button
                        className="w-full py-2 px-4 bg-green-500 text-white rounded-md"
                        onClick={handleDownload}
                    >
                        {t('DownloadConvertedFile')}
                    </button>
                </div>
            )}

            {/* Footer with API documentation */}
            <ToolFooter apiDocumentation={apiDocumentation} />
        </div>
    );
};

export default Lansforsakringar;
